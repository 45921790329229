import { Component, inject } from "@angular/core";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../../core/services/toast.service";

@Component({
  selector: "app-toast",
  standalone: true,
  imports: [NgbToastModule],
  template: `
    @for (toast of toastService.toasts; track toast) {
    <ngb-toast
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      {{ toast.text }}
    </ngb-toast>
    }
  `,
  styles: ``,
  host: {
    class: "toast-container position-fixed top-0 end-0 p-3 primary-text",
    style: "z-index: 1200",
  },
})
export class ToastComponent {
  toastService = inject(ToastService);
}
