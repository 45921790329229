import {Theme} from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    "--borders-border-card": "var(--colors-light-border-border-01)",
    "--borders-border-color-1": "var(--colors-light-border-border-01)",
    "--buttons-btn-bg-cta-sm": "var(--colors-light-background-bg-01)",
    "--buttons-btn-bg-disabled": "var(--colors-light-background-bg-05)",
    "--buttons-btn-cta-hover-bg": "#745ECC",
    "--buttons-btn-cta-sm-hover-bg": "var(--colors-light-background-bg-06)",
    "--buttons-btn-primary-hover-bg": "var(--colors-accents-q-blue-a)",
    "--buttons-btn-text-cta-sm": "var(--colors-light-text-gray-01)",
    "--buttons-btn-text-disabled": "var(--colors-light-text-gray-04)",
    "--buttons-btn-text-white": "var(--colors-light-text-gray-05)",
    "--surface-accent-color1": "#D4E1F9",
    "--surface-accent-color2": "#c5fcd1",
    "--surface-accent-color3": "#DCC5FC",
    "--surface-accent-color4": "#FFD8C2",
    "--surface-accent-color5": "#FFFDC2",
    "--surface-accent-color6": "#AD99FF",
    "--surface-accent-color7": "#C2FFE9",
    "--surface-accent-color8": "#FF97FF",
    "--surface-accent-color9": "#F49E9E",
    "--surface-accent-color10": "#FFB979",
    "--surface-accent-color11": "#FF6B62",
    "--surface-accent-color12": "#8EEFFD",
    "--surface-accent-color13": "#FE6BB2",
    "--surface-accent-color14": "#84ff9f",
    "--surface-accent-color15": "#FDB13F",
    "--surface-accent-color16": "#d0f581",
    "--surface-accent-color17": "#EF864B",
    "--surface-accent-color18": "#70BECD",
    "--surface-bg-body": "var(--colors-light-background-bg-08)",
    "--surface-bg-body-card": "var(--colors-light-background-bg-01)",
    "--surface-bg-console-primary": "var(--colors-light-background-bg-02)",
    "--surface-bg-console-secondary": "var(--colors-light-background-bg-05)",
    "--surface-bg-green": "var(--colors-light-background-bg-07)",
    "--surface-bg-menu-item": "var(--colors-light-background-bg-02)",
    "--surface-bg-primary": "var(--colors-light-background-bg-06)",
    "--surface-bg-white": "var(--colors-light-background-bg-01)",
    "--surface-bg-white-input": "var(--colors-light-background-bg-01)",
    "--surface-error-bg": "#F6D5D5",
    "--surface-icon-main-icon-bg": "var(--surface-bg-primary)",
    "--surface-logo-bg": "#F5F7FF",
    "--surface-modal-modal-bg": "#FFFFFF14",
    "--surface-status-bg-green": "var(--colors-light-background-bg-07)",
    "--surface-status-error-bg": "#F6D5D5",
    "--surface-status-warning-bg": "#F6EBD5",
    "--typography-text-caption": "var(--colors-light-text-gray-02)",
    "--typography-text-input-disabled": "#AFAFAFA5",
    "--typography-text-invert": "var(--colors-light-text-gray-05)",
    "--typography-text-link": "var(--colors-brand-primary-q-blue-p)",
    "--typography-text-primary": "var(--colors-light-text-gray-00)",
    "--typography-text-secondary": "var(--colors-light-text-gray-01)",
    // Below is a custom variable created to manage placeholder for input fields
    "--surface-bg-gradient-body": "rgb(245, 246, 255)",
    "--input-placeholder-custom-color": "var(--colors-light-text-gray-04)",
    "--color-purple": "var(--colors-brand-light-theme-purple)",
  },
};
