import {Routes} from '@angular/router';
import {PAGE_TITLE} from "./constants/constants";
import { devEnvGuard } from './core/guards/dev-env.guard';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/base/base.routes').then(r => r.BASE_ROUTES),
        // canActivate: [devEnvGuard],
    },
    // {
    //     path: '404',
    //     loadComponent: () => import('./pages/error-page/error-page.component').then(c => c.ErrorPageComponent),
    //     title: PAGE_TITLE.ERROR
    // },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'home'
    },
];
