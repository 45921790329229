import {Directive, ElementRef, OnInit} from '@angular/core';
import {lightTheme} from "./light-theme";

@Directive({
    selector: '[theme]',
    standalone: true
})
export class ThemeDirective implements OnInit {

    constructor(
        private _elementRef: ElementRef,
    ) {
    }

    ngOnInit(): void {
      this.updateTheme(lightTheme);
    }

    private updateTheme(activeThemeData: any) {
        for (const key in activeThemeData.properties) {
            this._elementRef.nativeElement.style.setProperty(key, activeThemeData.properties[key]);
        }
        // alias element with activeTheme name
        this._elementRef.nativeElement.classList.add(`${activeThemeData.name}-theme`);
    }
}
