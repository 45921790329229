import {
  Component,
} from "@angular/core";
import {
  RouterOutlet,
} from "@angular/router";
import { ThemeDirective } from "./theme/theme.directive";
import { CommonModule } from "@angular/common";
import {ToastComponent} from "./shared/modals/toast/toast.component";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    RouterOutlet,
    ThemeDirective,
    CommonModule,
    ToastComponent
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
}
